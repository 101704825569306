<template>
  <van-form ref="form" style="margin-top: 40px">
    <van-image :src="require('@/assets/login/denglu_logo_img.png')" width="139" height="133" style="margin-left: 50%; transform: translate(-50%)"/>
    <van-field
        style="margin-top: 74px; color: #999999; display: block"
        :label-class="'label-class'"
        v-model="mobileNumber"
        type="tel"
        name="手机号"
        label="手机号"
        placeholder="请输入手机号"
        :rules="[{ required: true}]"
    />
    <van-field
      style="margin-top: 10px; color: #999999; display: block"
      :label-class="'label-class'"
      v-model="smsCode"
      type="text"
      name="smsCode"
      label="验证码"
      placeholder="请输入验证码"
  >
    <template #button>
      <van-button size="small" color="#FFBD04" plain style="border-radius: 4px" @click="sendSmsCode">获取验证码</van-button>
    </template>
  </van-field>
    <!--<van-field
        style="color: #999999; display: block"
        :label-class="'label-class'"
        v-model="password"
        :type="passwordFieldType"
        name="密码"
        label="密码"
        placeholder="请输入密码"
        :rules="[{ required: true }]"
    >
      <template #right-icon>
        <van-image @click="togglePwIcon" width="15" height="15" :src="passwordFieldType === 'password' ? require('@/assets/login/dlu_bkj_icon.png') : require('@/assets/login/dlu_kejian_icon.png')" />
      </template>
    </van-field>
    <span class="forgot-password" @click="navTo('/reset')">忘记密码</span>-->
    <div style="margin: 140px 20px 28px 28px;">
      <van-button round block type="info" color="#FFBD04" @click="onSubmit" style="height: 55px; font-size: 18px">
        登录
      </van-button>
    </div>
<!--    <div style="text-align: center; font-size: 14px; font-weight: 500; color: #333333" @click="navTo('/register')">还没有账号？ <span style="color:#2394FD;">立即注册</span></div>-->
  </van-form>
</template>

<script>
import Client from "@/api"

export default {
  name: "login",
  data: function () {
    return {
      mobileNumber: undefined,
      // password: '',
      smsCode: undefined,
      // passwordFieldType: 'password',
      openId: undefined
    }
  },
  /*created() {
    setTimeout(() => {
      // alert(JSON.stringify(this.$route.query))
      let token = this.$route.query.token
      if (token && token !== '') {
        localStorage.setItem('token', token)
        this.navTo('/index')
      } else {
        let openId = this.$route.query.openId
        if (openId && openId !== '') {
          this.openId = openId
        }
      }
    }, 2000)
  },*/
  created() {
    // alert(JSON.stringify(this.$route.query))
  },
  methods: {
    sendSmsCode() {
      if (this.mobileNumber.length === 11) {
        this.$toast.loading()
        Client.get('courier/smsCode', {params: {phone: this.mobileNumber}}).then(res => {
          this.$toast.clear()
          if (res.data.state === 'ok') {
            this.$toast.success({message: '验证码发送成功'})
          } else {
            this.$toast.fail({message: res.data.msg})
          }
        }).catch(err => {
          this.$toast.clear()
          this.$toast.fail(err.response.data.msg)
        })
      } else {
        this.$toast.fail({message: '请输入正确的中国地区手机号码'})
      }
    },
    onSubmit() {
      Client.post('courier/smsLogin', {mobileNumber: this.mobileNumber, smsCode: this.smsCode, openId: this.$route.query.openId, userInfo: this.$route.query.userInfo}).then(res => {
        if (res.data.state === 'ok') {
          localStorage.setItem('token', res.data.data)
          // this.navTo('/index')
          // eslint-disable-next-line no-undef
          wx.miniProgram.reLaunch({url: `/pages/index/index`})
        } else {
          this.$toast.fail(res.data.msg)
        }
      }).catch(err => {
        console.log(err.response)
        this.$toast.fail('账号或密码错误')
      })
      // eslint-disable-next-line no-undef
    },
    togglePwIcon() {
      if (this.passwordFieldType === 'password') {
        this.passwordFieldType = 'text'
      } else {
        this.passwordFieldType = 'password'
      }
    }
  }
}
</script>

<style scoped>
/deep/ .label-class {
  margin-bottom: 22px;
  color: #999999;
  font-weight: 500;
  font-size: 13px;
}
/deep/ .van-field__control {
  font-size: 17px;
  font-weight: 500;
}
/deep/ .van-cell::after {
  left: 28px;
  right: 28px;
}
/deep/ .van-cell {
  padding: 10px 28px;
}
.forgot-password {
  right: 28px;
  position: absolute;
  margin-top: 15px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #FFBD04;
  line-height: 9px;
}
</style>
